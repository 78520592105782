import React, { createContext, useState, useEffect } from 'react';
import translationService from './services/TranslationService';

export const TranslationContext = createContext();

export const TranslationProvider = ({ children }) => {
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    const fetchTranslations = async () => {
      try {
        const response = await translationService.getTranslations();
         const formattedTranslations = response.reduce((acc, item) => {
          acc[item.key] = item.translations;
          return acc;
        }, {});
        setTranslations(formattedTranslations);
      } catch (error) {
        console.error('Error fetching translations:', error);
      }
    };

    fetchTranslations();
  }, []);

  return (
    <TranslationContext.Provider value={{ translations }}>
      {children}
    </TranslationContext.Provider>
  );
};