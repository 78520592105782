import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import { Button, Badge, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import { useTranslation } from '../../../hooks/useTranslation';

import ComponentCard from '../../../components/ComponentCard';
import centerService from '../../../services/CenterService';
import userService from '../../../services/UserService';
import { DejaVuSans } from '../fonts/DejaVuSans';

const LCFCenters = () => {
  const { t } = useTranslation();
  const [centers, setCenters] = useState([]);
  const [modal, setModal] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [masterFranchisors, setMasterFranchisors] = useState([]);
  const [lcfs, setLcfs] = useState([]);

  const [formData, setFormData] = useState({
    name: '',
    masterFranchisor: '',
    lcf: '',
    address: '',
    town: '',
    zipCode: '',
    phone: '',
    licencePrice: '',
    notes: '',
    active: '1'
  });

  const toggleModal = () => {
    setModal(!modal);
    if (!modal) {
      setFormData({
        name: '',
        masterFranchisor: '',
        lcf: '',
        address: '',
        town: '',
        zipCode: '',
        phone: '',
        licencePrice: '',
        notes: '',
        active: '1'
      });
    }
  };

  const getCenters = async () => {
    try {
      const response = await centerService.getAll();
      setCenters(response);
      setFilteredData(response);
    } catch (error) {
      console.error(t('Error fetching centers:'), error);
    }
  };

  const getMasterFranchisors = async () => {
    try {
      const response = await userService.getByRole('master');
      setMasterFranchisors(response);
    } catch (error) {
      console.error(t('Error fetching master franchisors:'), error);
    }
  };

  const getLcfs = async () => {
    try {
      const response = await userService.getByRole('lcf');
      setLcfs(response);
    } catch (error) {
      console.error(t('Error fetching LCFs:'), error);
    }
  };

  useEffect(() => {
    getCenters();
    getMasterFranchisors();
    getLcfs();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const centerData = { ...formData, active: formData.active === '1' };
    
    try {
      if (formData._id) {
        await centerService.update(formData);
      } else {
        await centerService.create(centerData);
      }
      getCenters();
      toggleModal();
    } catch (error) {
      console.error(t('Error saving center:'), error);
    }
  };

  const handleEdit = (center) => {
    console.log(center);  
    setFormData({
      _id: center._id,
      name: center.name,
      masterFranchisor: center.lcf_data?.master_n,
      lcf: center.lcf,
      address: center.adresa,
      town: center.grad,
      zipCode: center.zip,
      phone: center.telefon,
      licencePrice: center?.licenseprice,
      notes: center.notes,
      active: center.active ? '1' : '0'
    });
    setModal(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm(t('Are you sure you want to delete this center?'))) {
      try {
        await centerService.deleteCenter(id);
        getCenters();
      } catch (error) {
        console.error(t('Error deleting center:'), error);
      }
    }
  };

  const columns = [
    {
      Header: t('LCF Center name'),
      accessor: 'name',
    },
    {
      Header: t('Master Franchisor'),
      id: 'master',
      accessor: (row) => {
        const lcf = lcfs.find(l => l._id === row.lcf);
        const masterFranchisor = masterFranchisors.find(mf => mf._id === lcf?.masters?._id);
         return masterFranchisor ? `${masterFranchisor.name} ${masterFranchisor.lname}` : t('N/A');
      },
    },
    {
      Header: t('LCF'),
      id: 'lcf',
      accessor: (row) => {
        const lcf = lcfs.find(l => l._id === row.lcf);
        return lcf ? `${lcf.name} ${lcf.lname}` : t('N/A');
      },
    },
    {
      Header: t('Address'),
      accessor: 'adresa',
    },
    {
      Header: t('Town'),
      accessor: 'grad',
    },
    {
      Header: t('Zip code'),
      accessor: 'zip',
    },
    {
      Header: t('Status'),
      accessor: 'active',
      Cell: props => (
        <Badge color={props.value ? 'success' : 'danger'} pill>
          {props.value ? t('Active') : t('Inactive')}
        </Badge>
      )
    },
    {
      Header: t('Created at'),
      accessor: 'createdAt',
      Cell: ({ value }) => moment(value).format('DD.MM.YYYY')
    },
    {
      Header: t('Actions'),
      Cell: ({ original }) => (
        <>
          <Button color="info" size="sm" onClick={() => handleEdit(original)}>{t('Edit')}</Button>
          {' '}
          <Button color="danger" size="sm" onClick={() => handleDelete(original._id)}>{t('Delete')}</Button>
        </>
      )
    }
  ];

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";
    // eslint-disable-next-line new-cap
    const doc = new jsPDF(orientation, unit, size);

    doc.setFont("DejaVuSans", "normal");
    doc.setFontSize(15);

    const title = t("LCF Centers Report");
    const headers = columns.map(column => column.Header);

    const data = filteredData.map(item => [
      item.name,
      item.masterFranchisor.split('@')[0],
      item.lcf.split('@')[0],
      item.address,
      item.town,
      item.zipCode,
      item.active ? t('Active') : t('Inactive'),
      new Date(item.createdAt).toLocaleDateString()
    ]);

    const content = {
      startY: 50,
      head: [headers],
      body: data
    };

    doc.text(title, 40, 40);
    doc.autoTable(content);
    doc.save("lcf_centers_report.pdf")
  }

  return (
    <>
      {/* eslint-disable-next-line*/}
      <ComponentCard title={t('LCF Center')+` (${centers.length})`}>
        <div style={{marginBottom: '10px'}}>
          <Button color="primary" onClick={toggleModal} style={{marginRight: '10px'}}>{t('Add New Center')}</Button>
          <Button color="secondary" onClick={exportPDF}>{t('Print to PDF')}</Button>
        </div>
        <ReactTable
          columns={columns}
          data={centers}
          filterable
          defaultPageSize={50}
          className="-striped -highlight myTable"
        />
      </ComponentCard>

      <Modal isOpen={modal} toggle={toggleModal} size="lg">
        <Form onSubmit={handleSubmit}>
          <ModalHeader toggle={toggleModal}>{formData._id ? t('Edit LCF Center') : t('Add New LCF Center')}</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="masterFranchisor">{t('MF')}</Label>
              <Input type="select" name="masterFranchisor" id="masterFranchisor" value={formData.masterFranchisor} onChange={handleInputChange} required>
                <option value="">{t('Select Master Franchisor')}</option>
                {masterFranchisors.map(mf => (
                  <option key={mf._id} value={mf._id}>{mf.email}</option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="licencePrice">{t('Licence price')}</Label>
              <Input type="text" name="licencePrice" id="licencePrice" value={formData.licencePrice} onChange={handleInputChange} required />
            </FormGroup>
            <FormGroup>
              <Label for="lcf">{t('LCF')}</Label>
              <Input type="select" name="lcf" id="lcf" value={formData.lcf} onChange={handleInputChange} required>
                <option value="">{t('Select LCF')}</option>
                {lcfs.map(lcf => (
                  <option key={lcf._id} value={lcf._id}>{lcf.email}</option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="name">{t('Title')}</Label>
              <Input type="text" name="name" id="name" value={formData.name} onChange={handleInputChange} required />
            </FormGroup>
            <FormGroup>
              <Label for="address">{t('Address')}</Label>
              <Input type="text" name="address" id="address" value={formData.address} onChange={handleInputChange} required />
            </FormGroup>
            <FormGroup>
              <Label for="town">{t('City')}</Label>
              <Input type="text" name="town" id="town" value={formData.town} onChange={handleInputChange} required />
            </FormGroup>
            <FormGroup>
              <Label for="zipCode">{t('Zip code')}</Label>
              <Input type="text" name="zipCode" id="zipCode" value={formData.zipCode} onChange={handleInputChange} required />
            </FormGroup>
            <FormGroup>
              <Label for="phone">{t('Phone')}</Label>
              <Input type="text" name="phone" id="phone" value={formData.phone} onChange={handleInputChange} required />
            </FormGroup>
            <FormGroup>
              <Label for="notes">{t('Notes')}</Label>
              <Input type="textarea" name="notes" id="notes" value={formData.notes} onChange={handleInputChange} />
            </FormGroup>
            <FormGroup>
              <Label for="active">{t('Active')}</Label>
              <Input type="select" name="active" id="active" value={formData.active} onChange={handleInputChange}>
                <option value="1">{t('Yes')}</option>
                <option value="0">{t('No')}</option>
              </Input>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">{t('Save')}</Button>{' '}
            <Button color="secondary" onClick={toggleModal}>{t('Close')}</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default LCFCenters;