import fetchRequest from '../auth/FetchInterceptorRequests'

const translationService = {
    getTranslations: function () {
        return fetchRequest({
            url: '/digital/translations',
            method: 'get'
        })
    },

    saveTranslations: function (translations) {
        return fetchRequest({
            url: '/digital/translations',
            method: 'post',
            data: { translations }
        })
    },

    getCountries: function () {
        return fetchRequest({
            url: '/digital/countries',
            method: 'get'
        })
    }
}

export default translationService;