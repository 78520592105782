import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { TranslationContext } from '../TranslationContext';

export const useTranslation = () => {
  const { translations } = useContext(TranslationContext);
  const userLanguage =  localStorage.getItem('lng') || 'en';

  const t = (key) => {
    if (translations[key] && translations[key][userLanguage]) {
      return translations[key][userLanguage];
    }
    return key; // Fallback to key if translation not found
  };

  return { t };
};