
import fetchRequest from '../auth/FetchInterceptorRequests'

const participantsService = {}

participantsService.getAllByEvents = function (ids) {
	return fetchRequest({
		url: `/digital/participations/event/all`,
		data: {ids:ids},
		method: 'post'
 	})
}

participantsService.getAllByUser = function (lcf) {
    return fetchRequest({
        url: `/digital/participations/user/all`,
        method: 'get',
		params: {lcf:lcf}
 	})
}

export default participantsService;