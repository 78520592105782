import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import { Button, Badge, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import ComponentCard from '../../../components/ComponentCard';
import userService from '../../../services/UserService';
import { DejaVuSans } from '../fonts/DejaVuSans';

const Admins = () => {
  const [users, setUsers] = useState([]);
  const [modal, setModal] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [passwordError, setPasswordError] = useState('');

  const [formData, setFormData] = useState({
    name: '',
    lname: '',
    email: '',
    password: '',
    active: '1'
  });

  const toggleModal = () => {
    setModal(!modal);
    if (!modal) {
      setFormData({
        name: '',
        lname: '',
        email: '',
        password: '',
        active: '1'
      });
    }
  };

  const getUsers = async () => {
    try {
      const response = await userService.getByRole('admin');
      setUsers(response);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };
   

  const validatePasswords = (password, repassword) => {
    if (password && repassword && password !== repassword) {
      setPasswordError('Passwords do not match');
    } else {
      setPasswordError('');
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    if (name === 'repassword' || name === 'password') {
      validatePasswords(name === 'password' ? value : formData.password, name === 'repassword' ? value : formData.repassword);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const userData = { ...formData, active: formData.active === '1' , role: 'admin'};
    
    try {
      if (formData._id) {
        if (formData.password === '111111') {
          delete userData.password;
        }
        await userService.updateUser(formData._id, userData);
      } else {
        await userService.createUser(userData);
      }
      getUsers();
      toggleModal();
    } catch (error) {
      console.error('Error saving user:', error);
    }
  };

  const handleEdit = (user) => {
    setFormData({
      _id: user._id,
      name: user.name,
      password: '111111',
      repassword: '111111',
      lname: user.lname,
      email: user.email,
      active: user.active ? '1' : '0'
    });
    setModal(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        await userService.deleteUser(id);
        getUsers();
      } catch (error) {
        console.error('Error deleting user:', error);
      }
    }
  };
  const columns = [
    {
      Header: 'Name',
      id: 'name',
      accessor: (row) => `${row.name} ${row.lname}`,
    },
    {
      Header: 'Last Name',
      accessor: 'lname',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Status',
      accessor: 'active',
      Cell: props => (
        <Badge color={props.value ? 'success' : 'danger'} pill>
          {props.value ? 'Active' : 'Inactive'}
        </Badge>
      )
    },
    {
      Header: 'Actions',
      Cell: ({ original }) => (
        <>
          <Button color="info" size="sm" onClick={() => handleEdit(original)}>Edit</Button>
          {' '}
          <Button color="danger" size="sm" onClick={() => handleDelete(original._id)}>Delete</Button>
        </>
      )
    }]
  
  const exportPDF = (columnsall,dataToExport) => {
    // eslint-disable-next-line new-cap
const doc = new jsPDF({
   orientation: 'landscape',
   unit: 'mm',
   format: 'a4'
 });

 // Embed the DejaVuSans font
 doc.addFileToVFS("DejaVuSans.ttf", DejaVuSans);
 doc.addFont("DejaVuSans.ttf", "DejaVuSans", "normal");
 doc.setFont("DejaVuSans"); // Set the custom font

 // Add a simple header
 doc.setFillColor(66, 139, 202);
 doc.rect(0, 0, 297, 15, 'F');
 doc.setTextColor(255, 255, 255);
 doc.setFontSize(12);
 doc.text('LCFs Report', 10, 10);

 // Add current date to header
 const currentDate = new Date().toLocaleDateString();
 doc.setFontSize(10);
 doc.text(currentDate, 270, 10, { align: 'right' });

 // Reset text color for the main content
 doc.setTextColor(0, 0, 0);

 const tableStartY = 20;
 doc.autoTable({
   head: [columnsall.map(column => column.Header)],
   body: dataToExport.map(user => [
     `${user.name} ${user.lname}`,
     user.email,
     user.country ? user.country.name : 'N/A',
     `${user.fee}%`,
     user.active ? 'Active' : 'Inactive'
   ]),
   startY: tableStartY,
   styles: { 
     font: 'DejaVuSans', 
     fontSize: 9,
     cellPadding: 3,
   },
   headStyles: { 
     fillColor: [66, 139, 202], 
     textColor: [255, 255, 255],
     fontSize: 10,
     fontStyle: 'bold',
   },
   columnStyles: {
    
   },
   alternateRowStyles: {
     fillColor: [240, 248, 255]
   },
   didDrawPage: function (data) {
     // Footer
     const str = `Page ${doc.internal.getNumberOfPages()}`;
     doc.setFontSize(10);
     doc.text(str, 287, 200, { align: 'right' });
   }
 });

 // Add a summary after the table
 const finalY = doc.lastAutoTable.finalY || tableStartY;
 doc.setFontSize(10);
 doc.text(`Total : ${dataToExport.length}`, 10, finalY + 10);

 doc.save("admins_report.pdf");

}
  return (
    <>
      <ComponentCard title={`Users (${users.length ?? 0})`}>
        <Button color="primary" onClick={toggleModal}>Add New Admin</Button>
        <Button color="secondary" onClick={()=>exportPDF(columns,filteredData.length?filteredData:users)}>Print to PDF</Button>

        <ReactTable
          columns={columns}
          defaultPageSize={10}
          className="-striped -highlight myTable"
          data={users}
          filterable
         
        />
      </ComponentCard>

      <Modal isOpen={modal} toggle={toggleModal}>
        <Form onSubmit={handleSubmit}>
          <ModalHeader toggle={toggleModal}>{formData._id ? 'Edit Admin' : 'Add New Admin'}</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="name">First Name</Label>
              <Input type="text" name="name" id="name" value={formData.name} onChange={handleInputChange} required />
            </FormGroup>
            <FormGroup>
              <Label for="lname">Last Name</Label>
              <Input type="text" name="lname" id="lname" value={formData.lname} onChange={handleInputChange} required />
            </FormGroup>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input type="email" name="email" id="email" value={formData.email} onChange={handleInputChange} required />
            </FormGroup>
             
              <FormGroup>
                <Label for="password">Password</Label>
                <Input type="password" name="password" id="password" value={formData.password} onChange={handleInputChange} required />
              </FormGroup>
              <FormGroup>
                <Label for="repassword">Re-enter Password</Label>
                <Input 
              type="password" 
              name="repassword" 
              id="repassword" 
              value={formData.repassword} 
              onChange={handleInputChange}
               invalid={!!passwordError}
              required 
            />       
                        <FormFeedback>{passwordError}</FormFeedback>

                   </FormGroup>
           
            <FormGroup>
              <Label for="active">Status</Label>
              <Input type="select" name="active" id="active" value={formData.active} onChange={handleInputChange}>
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </Input>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">Save</Button>{' '}
            <Button color="secondary" onClick={toggleModal}>Cancel</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default Admins;