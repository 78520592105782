import fetchRequest from '../auth/FetchInterceptorRequests'

const licenseService = {}

licenseService.getAll = function () {
	return fetchRequest({
		url: '/digital/licenses',
		method: 'get'
	})
}

licenseService.get = function (data) {
	return fetchRequest({
		url: `/digital/licenses/${data.id}`,
		method: 'get'
	})
}

export default licenseService;