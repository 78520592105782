import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from '../constants/ThemeConstant';
import { env } from './EnvironmentConfig'
import { LNG } from '../constants/AuthConstant';

export const APP_NAME = 'Tinkerlabs IMS';
export const DEVELOPERS_NAME = 'DDSOFT & SIADS';
export const API_BASE_URL = env.API_ENDPOINT_URL
export const APP_PREFIX_PATH = '/app';
export const ADMIN_PREFIX_PATH = '/admin';
export const MASTER_PREFIX_PATH = '/master';
export const AUTH_PREFIX_PATH = '/auth';
export const REDIRECT_URL_KEY = 'redirect'
export const AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/`;
export const UNAUTHENTICATED_ENTRY = '/login'
export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: localStorage.getItem(LNG)?localStorage.getItem(LNG): 'en',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#3e82f7',
	headerNavColor: '#e5e5f0ff',
	mobileNav: false,
	currentTheme: 'light',
	direction: DIR_LTR,
	blankLayout: false
};