// GlobalTranslationProvider.js
import React, { createContext, useContext, useCallback } from 'react';
import { useTranslation } from  "./useTranslation";

const TranslationContext = createContext();

export const useGlobalTranslation = () => useContext(TranslationContext);

export const GlobalTranslationProvider = ({ children }) => {
  const { t } = useTranslation();

  const translateText = useCallback((text) => {
    if (typeof text !== 'string') return text;
    const translated = t(text);
    console.log(`Translating: "${text}" => "${translated}"`);
    return translated;
  }, [t]);

  const translateProps = useCallback((element) => {
    if (!React.isValidElement(element)) {
      return element;
    }

    const newProps = Object.entries(element.props).reduce((acc, [key, value]) => {
      if (typeof value === 'string') {
        acc[key] = translateText(value);
      } else {
        acc[key] = value;
      }
      return acc;
    }, {});

    return React.cloneElement(
      element,
      newProps,
      React.Children.map(element.props.children, child => 
        typeof child === 'string' ? translateText(child) : translateProps(child)
      )
    );
  }, [translateText]);

  return (
    <TranslationContext.Provider value={{ translateText, translateProps }}>
      {React.Children.map(children, child => translateProps(child))}
    </TranslationContext.Provider>
  );
};