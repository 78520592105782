import fetchRequest from '../auth/FetchInterceptorRequests'

const countryService = {}

countryService.getAll = function () {
	return fetchRequest({
		url: '/digital/countries',
		method: 'get'
	})
}

countryService.get = function (data) {
	return fetchRequest({
		url: `/digital/countries/${data.id}`,
		method: 'get'
	})
}

export default countryService;