
import fetchRequest from '../auth/FetchInterceptorRequests'

const coursesService = {}

coursesService.coursesWithStudents = function () {
	return fetchRequest({
		url: `/digital/courseswithstudents`,
		method: 'get'
	})
}

coursesService.create = function (data) {	
	return fetchRequest({
		url: '/digital/courses',
		method: 'post',
		data: data
	})
}

coursesService.update = function (id,data) {
	return fetchRequest({
		url: `/digital/courses/${id}`,
		method: 'put',
		data: data
	})
}	

coursesService.courseWithStudents = function (id) {
	return fetchRequest({
		url: `/digital/courseswithstudents/${id}`,
		method: 'get'
	})
}

coursesService.getAll = function () {
    return fetchRequest({
        url: '/digital/courses',
        method: 'get'
    })
}

coursesService.delete = function (id) {
	return fetchRequest({
		url: `/digital/courses/${id}`,
		method: 'delete'
	})
}

export default coursesService;