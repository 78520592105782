import React from 'react';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';

const TinkerTable = ({columns,data}) => {
    const filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        const content = row[id];
        if (typeof content !== 'undefined') {
            // filter by text in the table or if it's a object, filter by key
            if (typeof content === 'object' && content !== null && content.key) {
                return String(content.key).toLowerCase().includes(filter.value.toLowerCase());
            }  
                return String(content).toLowerCase().includes(filter.value.toLowerCase());
            
        }
    
        return true;
    };

return (  <ReactTable
columns={columns}
defaultPageSize={50}
className="-striped -highlight myTable"
 
data={data}
filterable
defaultFilterMethod={filterCaseInsensitive} 

/>
);
}
export default TinkerTable;