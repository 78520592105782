import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from '../AuthContext'; // Adjust this import to your auth context

const PublicRoute = ({ children }) => {

    PublicRoute.propTypes = {
        children: PropTypes.node.isRequired
    };
  const { user } = useAuth();
useEffect(() => {
    // Check if user is already authenticated (e.g., token exists in localStorage)
    console.log('children', children);
  }
  , []);
  return !user ? children : <Navigate to="/" />;
};

export default PublicRoute;
