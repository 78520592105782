import fetch from '../auth/FetchInterceptor'
import fetchRequest from '../auth/FetchInterceptorRequests'

const AuthService = {}

AuthService.login = function f(data) {
	return fetch({
		url: '/login',
		method: 'post',
		data: data
	})
}

AuthService.loginMascarade = function f(id) {
	return fetch({
		url: '/digital/loginMascarade',
		method: 'post',
		data: {id:id}
	})
}

AuthService.requireResetPass = function f(data) {
	return fetch({
		url: '/password/email',
		method: 'post',
		data: data
	})
}

AuthService.resetPassword = function f(data) {
	return fetch({
		url: '/password/reset',
		method: 'post',
		data: data
	})
}

AuthService.register = function f(data) {
	return fetch({
		url: '/users/register',
		method: 'post',
		data: data
	})
}
AuthService.newUser = function f(data) {
	return fetchRequest({
		url: '/users/newregister',
		method: 'post',
		data: data
	})
}
AuthService.update = function f(data) {
	return fetchRequest({
		url: `/users/${data.id}`,
		method: 'put',
		data: data
	})
}
AuthService.logout = function f() {
	return fetch({
		url: '/auth/logout',
		method: 'post'
	})
}

AuthService.loginInOAuth = function f() {
	return fetch({
		url: '/auth/loginInOAuth',
		method: 'post'
	})
}

export default AuthService;