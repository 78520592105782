import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import {
  Button,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from 'reactstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import ComponentCard from '../../../components/ComponentCard';
import userService from '../../../services/UserService';
import { DejaVuSans } from '../fonts/DejaVuSans';
import licenseService from '../../../services/LicenseService';
import { useTranslation } from '../../../hooks/useTranslation';
import { use } from 'i18next';
import { notification, Popover, Select } from 'antd';
import AuthService from '../../../services/AuthService';

const { Option } = Select;

const Teachers = () => {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [modal, setModal] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [masterFranchisors, setMasterFranchisors] = useState([]);
  const [lcfs, setLcfs] = useState([]);
  const [licences, setLicences] = useState([]);
  const [passwordError, setPasswordError] = useState('');
  const user = JSON.parse(localStorage.getItem('user'));
  const [formData, setFormData] = useState({
    name: '',
    lname: '',
    email: '',
    password: '',
    active: '1',
    in_bussines: '1',
    master: masterFranchisors.length === 1 ? user.master_id : '',
    lcf: '',
    licence: [],
  });

  const toggleModal = () => {
    setModal(!modal);
    if (!modal) {
      setFormData({
        name: '',
        lname: '',
        email: '',
        password: '',
        active: '1',
        in_bussines: '1',
        master: user.role === 'master' ? user._id :user.role === 'lcf' ? user.master_n :'',
        lcf: user.role === 'lcf' ? user._id :'',
        licence: [],
      });
    }
  };
  const filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    const content = row[id];
    if (typeof content !== 'undefined') {
        // filter by text in the table or if it's a object, filter by key
        if (typeof content === 'object' && content !== null && content.key) {
            return String(content.key).toLowerCase().includes(filter.value.toLowerCase());
        }  
            return String(content).toLowerCase().includes(filter.value.toLowerCase());
        
    }

    return true;
};
const mascaradeAsUser =async (user) => {
  try {
  const tempUser = JSON.parse(localStorage.getItem('user'));
  localStorage.setItem('tempUser', JSON.stringify(tempUser));
  const tempToken = localStorage.getItem('token');
  localStorage.setItem('tempToken', tempToken);
//  localStorage.removeItem('token');
const logindata = await AuthService.loginMascarade(user._id);

 localStorage.setItem('isMasquerading', 'true')
  localStorage.setItem('user', JSON.stringify(logindata.user));
  localStorage.setItem('token', logindata.token);
  // Navigate to homepage and then reload
  window.location.href = '/';
  // Use a short timeout to ensure the navigation happens before the reload
  setTimeout(() => {
    window.location.reload();
  }, 100);
}
catch (error) {
  notification.error({
    message: t('Error'),
    description: t('Error masquerading as user'),
  });
}
};
  const getUsers = async () => {
    try {
      const response = await userService.getByRole('teacher');
      setUsers(response);
    } catch (error) {
      console.error(t('Error fetching users:'), error);
    }
  };

  const getMasterFranchisors = async () => {
    try {
      const response = await userService.getByRole('master');
      setMasterFranchisors(response);
    } catch (error) {
      console.error(t('Error fetching master franchisors:'), error);
    }
  };

  const getLcfs = async () => {
    try {
      const response = await userService.getByRole('lcf');
      setLcfs(response);
    } catch (error) {
      console.error(t('Error fetching LCFs:'), error);
    }
  };

  const getLicences = async () => {
    try {
      const response = await licenseService.getAll();
      setLicences(response);
    } catch (error) {
      console.error(t('Error fetching licences:'), error);
    }
  };

  useEffect(() => {
    getUsers();
    getMasterFranchisors();
    getLcfs();
    getLicences();
  }, []);

  const validatePasswords = (password, repassword) => {
    if (password && repassword && password !== repassword) {
      setPasswordError(t('Passwords do not match'));
    } else {
      setPasswordError('');
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === 'repassword' || name === 'password') {
      validatePasswords(
        name === 'password' ? value : formData.password,
        name === 'repassword' ? value : formData.repassword,
      );
    }
  };

  const handleLicenceChange = (event) => {
    
  

    setFormData((prevState) => ({
      ...prevState,
      licence: event,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const userData = {
      ...formData,
      active: formData.active === '1' ? 1 : 0,
      role: 'teacher',
      master: formData.master,
      master_n:formData.master,
    };

    try {
      if (formData._id) {
        if (formData.password === '111111') {
          delete userData.password;
        }
        await userService.updateUser(formData._id, userData);
      } else {
        await userService.createUser(userData);
      }
      getUsers();
      toggleModal();
    } catch (error) {
      console.error(t('Error saving user:'), error);
    }
  };

  const handleEdit = (user1) => {
    setFormData({
      _id: user1._id,
      name: user1.name,
      lname: user1.lname,
      email: user1.email,
      password: '111111',
      repassword: '111111',
      active: user1.active ? '1' : '0',
      in_bussines: user1.in_bussines ? '1' : '0',
      master:user.role ==="master" ?user._id :user.role ==="lcf"?user1.master_n : user1.master[0].$oid,
      lcf: user.role ==="lcf" ?user._id : user1.lcf,
      licence: user1.licence,
    });
    setModal(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm(t('Are you sure you want to delete this user?'))) {
      try {
        await userService.deleteUser(id);
        getUsers();
      } catch (error) {
        console.error(t('Error deleting user:'), error);
      }
    }
  };

  const columns = [
    {
      Header: t('Name'),
      id: 'name',
      accessor: (row) => `${row.name} ${row.lname}`,
    },
    {
      Header: t('Email'),
      accessor: 'email',
    },
    {
      Header: t('Master Franchisor'),
      id: 'masters',
      accessor: (row) => (row.masters ? `${row.masters.name} ${row.masters.lname}` : t('N/A')),
    },
    {
      Header: t('LCF email'),
      id: 'lcf_email',
      accessor: (row) => {
        const lcf = lcfs.find((l) => l._id === row.lcf);
        return lcf ? `${lcf.email}` : t('N/A');
      },
    },
    {
      Header: t('LCF'),
      id: 'lcf',
      accessor: (row) => {
        const lcf = lcfs.find((l) => l._id === row.lcf);
        return lcf ? `${lcf.name} ${lcf.lname}` : t('N/A');
      },
    },
    {
      Header: t('Licenses'),
      id: 'licence',
      accessor: (row) => {
        const a=  row.licence?.map((lic) => {
            const licence = licences.find((l) => l._id === lic);
            return licence ? <p>{licence.name }</p> :<p>{t('N/A')}</p>;
          })
        
          return <Popover content={a} title={`${row.name} ${row.lname}`}>
            <>
    <Button style={{float:'right',marginRight:10}} className="float-right" color="info" size='sm' >{t("Licences")}</Button>
    </>
  </Popover>
      },
    },
    {
      Header: t('Status'),
      accessor: 'active',
      Cell: (props) => (
        <Badge color={props.value ? 'success' : 'danger'} pill>
          {props.value ? t('Active') : t('Inactive')}
        </Badge>
      ),
    },
    {
      Header: t('In business'),
      accessor: 'in_bussines',
      Cell: (props) => (
        <Badge color={props.value ? 'success' : 'danger'} pill>
          {props.value ? t('Yes') : t('No')}
        </Badge>
      ),
    },
      {
      Header: t('Actions'),
      show: user.role === 'admin',
      innerWidth: 250,
      outerWidth: 250,
      Cell: ({ original }) => (
        <>
          <Button color="info" size="sm" onClick={() => handleEdit(original)}>
            {t('Edit')}
          </Button>{' '}
          <Button color="danger" size="sm" onClick={() => handleDelete(original._id)}>
            {t('Delete')}
          </Button>
          { user.role === 'admin'  && <Button color="warning" size="sm" onClick={() => mascaradeAsUser(original)}>{t('Mascarede')}</Button>}

        </>
      ),
    },
  ];

  const exportPDF = (columnsall, dataToExport) => {
    // ... (keep your existing exportPDF function)
  };

  return (
    <>
      <ComponentCard title={`${t('Teachers')} (${users.length ?? 0})`}>
       {user.role === "admin" && <Button color="primary" onClick={toggleModal}>
          {t('Add New Teacher')}
        </Button>}
        <Button
          color="secondary"
          onClick={() => exportPDF(columns, filteredData.length ? filteredData : users)}
        >
          {t('Print to PDF')}
        </Button>

        <ReactTable
          columns={columns}
          defaultPageSize={50}
          className="-striped -highlight myTable"
          data={users}
          filterable
          defaultFilterMethod={filterCaseInsensitive}
        />
      </ComponentCard>

      <Modal isOpen={modal} toggle={toggleModal} size="lg">
        <Form onSubmit={handleSubmit}>
          <ModalHeader toggle={toggleModal}>
            {formData._id ? t('Edit Teacher') : t('Add New Teacher')}
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="master">{t('Master Franchisor')}</Label>
              <Input
                type="select"
                name="master"
                id="master"
                value={formData.master}
                onChange={handleInputChange}
                disabled={masterFranchisors.length === 1 && user.role !== 'admin'}
              >
                <option value="">{t('Select Master Franchisor')}</option>
                {masterFranchisors.map((master) => (
                  <option key={master._id} value={master._id}>
                    {`${master.name} ${master.lname} - ${master.email}`}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="lcf">{t('LCF')}</Label>
              <Input
                type="select"
                name="lcf"
                id="lcf"
                value={formData.lcf}
                onChange={handleInputChange}
                disabled={lcfs.length === 1 && user.role !== 'admin' && user.role !== 'master'}
              >
                <option value="">{t('Select LCF')}</option>
                {lcfs.map((lcf) => (
                  <option
                    key={lcf._id}
                    value={lcf._id}
                  >{`${lcf.name} ${lcf.lname} - ${lcf.email}`}</option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="name">{t('Name')}</Label>
              <Input
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="lname">{t('Last Name')}</Label>
              <Input
                type="text"
                name="lname"
                id="lname"
                value={formData.lname}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="email">{t('Email')}</Label>
              <Input
                type="email"
                name="email"
                id="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="password">{t('Password')}</Label>
              <Input
                type="password"
                name="password"
                id="password"
                value={formData.password}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="repassword">{t('Re-enter Password')}</Label>
              <Input
                type="password"
                name="repassword"
                id="repassword"
                value={formData.repassword}
                onChange={handleInputChange}
                invalid={!!passwordError}
                required
              />
              <FormFeedback>{passwordError}</FormFeedback>
            </FormGroup>
            {user.role === 'admin' && <FormGroup>
              <Label for="licence">{t('Licence')}</Label>
              
              <Select
                mode="multiple"
                    name="licence"
                 id="licence"
                style={{ width: '100%' }}
                placeholder={t('Licence')}
                value={formData.licence}
                onChange={handleLicenceChange}
              >
                {licences.map((license) => (
                  <Option key={license._id} value={license._id}>
                    {license.name}
                  </Option>
                ))}
              </Select>
            </FormGroup>}
           {user.role === 'admin' && <FormGroup>
              <Label for="active">{t('Active')}</Label>
              <Input
                type="select"
                name="active"
                id="active"
 
                value={formData.active}
                onChange={handleInputChange}
              >
                <option value="1">{t('Yes')}</option>
                <option value="0">{t('No')}</option>
              </Input>
            </FormGroup>}
            <FormGroup>
              <Label for="in_bussines">{t('In business')}</Label>
              <Input
                type="select"
                name="in_bussines"
                id="in_bussines"
                value={formData.in_bussines}
                onChange={handleInputChange}
              >
                <option value="1">{t('Yes')}</option>
                <option value="0">{t('No')}</option>
              </Input>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              {t('Save')}
            </Button>{' '}
            <Button color="secondary" onClick={toggleModal}>
              {t('Close')}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default Teachers;