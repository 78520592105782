import React, { useState, useEffect } from 'react';
import { Button, Badge, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, FormFeedback, Col } from 'reactstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Row } from 'antd';
import { useTranslation } from '../../../hooks/useTranslation';
import { DejaVuSans } from '../fonts/DejaVuSans';
import ComponentCard from '../../../components/ComponentCard';
import userService from '../../../services/UserService';
import countryService from '../../../services/CountryService';
import TinkerTable from '../../ui/TinkerTable';
import LCFCenters from '../franchise/LCFCenters';
import { hi } from 'date-fns/locale';
import AuthService from '../../../services/AuthService';
import { navigate } from 'react-big-calendar/lib/utils/constants';

const Lcfs = () => {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [countries, setCountries] = useState([]);
  const [modal, setModal] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [passwordError, setPasswordError] = useState('');
  const user = JSON.parse(localStorage.getItem('user'));
  const isReadOnly = user.role !== 'admin';

  const [formData, setFormData] = useState({
    name: '',
    lname: '',
    email: '',
    password: '',
    confirmPassword: '',
    country: '',
    role: 'lcf',
    fee: '',
    active: '1',
    in_bussines: '1'
  });

  const toggleModal = () => {
    setModal(!modal);
    if (!modal) {
      setFormData({
        name: '',
        lname: '',
        email: '',
        password: '',
        confirmPassword: '',
        country: user.role === 'lcf' ? user.country : '',
        role: 'lcf',
        fee: '',
        active: '1',
        in_bussines: '1'
      });
    }
  };

  const getUsers = async () => {
    try {
      const response = await userService.getByRole('lcf');
      setUsers(response);
    } catch (error) {
      console.error(t('Error fetching users:'), error);
    }
  };

  const getCountries = async () => {
    try {
      let response = await countryService.getAll();
      if(user.role === 'lcf' || user.role === 'teacher') {
        response = response.filter((country) => country._id === user.country);
      }
      if(user.role === 'master') {
        response = response.filter((country) => country.master === user._id);
      }
      setCountries(response);
    } catch (error) {
      console.error(t('Error fetching countries:'), error);
    }
  };

  useEffect(() => {
    getUsers();
    getCountries();
  }, []);

  const validatePasswords = (password, repassword) => {
    if (password && repassword && password !== repassword) {
      setPasswordError(t('Passwords do not match'));
    } else {
      setPasswordError('');
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    if (name === 'repassword' || name === 'password') {
      validatePasswords(name === 'password' ? value : formData.password, name === 'repassword' ? value : formData.repassword);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
   
    const userData = { 
      ...formData, 
      active: formData.active === '1',
      in_bussines: formData.in_bussines === '1',
      fee: parseFloat(formData.fee)
    };
    delete userData.confirmPassword;
    
    try {
      if (formData._id) {
        if (formData.password === '111111') {
          delete userData.password;
        }
        await userService.updateUser(formData._id, userData);
      } else {
        await userService.createUser(userData);
      }
      getUsers();
      toggleModal();
    } catch (error) {
      console.error(t('Error saving user:'), error);
      alert(t('Error saving user. Please try again.'));
    }
  };

  const handleEdit = (user2) => {
    setFormData({
      _id: user2._id,
      name: user2.name,
      lname: user2.lname,
      password: '111111',
      repassword: '111111',
      email: user2.email,
      country: user2.country ? user2.country._id : '',
      role: user2.role,
      fee: user2.fee,
      active: user2.active ? '1' : '0',
      in_bussines: user2.in_bussines ? '1' : '0'
    });
    setModal(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm(t('Are you sure you want to delete this user?'))) {
      try {
        await userService.deleteUser(id);
        getUsers();
      } catch (error) {
        console.error(t('Error deleting user:'), error);
        alert(t('Error deleting user. Please try again.'));
      }
    }
  };

  const columns = [
    {
      Header: t('Name'),
      id: 'fullName',
      accessor: row => `${row.name} ${row.lname}`,
    },
    {
      Header: t('Email'),
      accessor: row => row.email ? row.email : t('N/A'),
    },
    {
      Header: t('Country'),
      id: 'countryName',
      accessor: row => row.country ? row.country.name : t('N/A'),
    },
    {
      Header: t('Fee'),
      accessor: 'fee',
      Cell: props => t('{{value}}%', { value: props.value })
    },
    {
      Header: t('Status'),
      accessor: 'active',
      Cell: props => (
        <Badge color={props.value ? 'success' : 'danger'} pill>
          {props.value ? t('Active') : t('Inactive')}
        </Badge>
      )
    },
    {
      Header: t('Actions'),
      id: 'actions',
      Cell: ({ original }) => (
        <>
          <Button color="info" size="sm" onClick={() => handleEdit(original)}>{t('Edit')}</Button>
          {' '}
          <Button color="danger" size="sm" onClick={() => handleDelete(original._id)}>{t('Delete')}</Button>
        </>
      )
    }
  ];

  const mascaradeAsUser =async (user) => {
    const tempUser = JSON.parse(localStorage.getItem('user'));
    localStorage.setItem('tempUser', JSON.stringify(tempUser));
    const tempToken = localStorage.getItem('token');
    localStorage.setItem('tempToken', tempToken);
  //  localStorage.removeItem('token');
  const logindata = await AuthService.loginMascarade(user._id);
 
   localStorage.setItem('isMasquerading', 'true')
    localStorage.setItem('user', JSON.stringify(logindata.user));
    localStorage.setItem('token', logindata.token);
    // Navigate to homepage and then reload
    window.location.href = '/';
    // Use a short timeout to ensure the navigation happens before the reload
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };
  
  const exportPDF = (columnsall, dataToExport) => {
    //eslint-disable-next-line
    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'mm',
      format: 'a4'
    });
    
    doc.addFileToVFS("DejaVuSans.ttf", DejaVuSans);
    doc.addFont("DejaVuSans.ttf", "DejaVuSans", "normal");
    doc.setFont("DejaVuSans");
    
    doc.setFillColor(66, 139, 202);
    doc.rect(0, 0, 297, 15, 'F');
    doc.setTextColor(255, 255, 255);
    doc.setFontSize(12);
    doc.text(t('LCFs Report'), 10, 10);
    
    const currentDate = new Date().toLocaleDateString();
    doc.setFontSize(10);
    doc.text(currentDate, 270, 10, { align: 'right' });
    
    doc.setTextColor(0, 0, 0);
    
    const tableStartY = 20;
    doc.autoTable({
      head: [columnsall.map(column => column.Header)],
      body: dataToExport.map(user1 => [
        `${user1.name} ${user1.lname}`,
        user1.email,
        user1.country ? user1.country.name : t('N/A'),
        // eslint-disable-next-line
        t('fee')+' '+user1.fee+ '%',
        user1.active ? t('Active') : t('Inactive')
      ]),
      startY: tableStartY,
      styles: { 
        font: 'DejaVuSans', 
        fontSize: 9,
        cellPadding: 3,
      },
      headStyles: { 
        fillColor: [66, 139, 202], 
        textColor: [255, 255, 255],
        fontSize: 10,
        fontStyle: 'bold',
      },
      columnStyles: {},
      alternateRowStyles: {
        fillColor: [240, 248, 255]
      },
      didDrawPage: function (data) {
        const str = t('Page {{page}}', { page: doc.internal.getNumberOfPages() });
        doc.setFontSize(10);
        doc.text(str, 287, 200, { align: 'right' });
      }
    });
    
    const finalY = doc.lastAutoTable.finalY || tableStartY;
    doc.setFontSize(10);
    doc.text(t('Total LCFs: {{count}}', { count: dataToExport.length }), 10, finalY + 10);
    
    doc.save("lcfs_report.pdf");
  }
  const filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    const content = row[id];
    if (typeof content !== 'undefined') {
        // filter by text in the table or if it's a object, filter by key
        if (typeof content === 'object' && content !== null && content.key) {
            return String(content.key).toLowerCase().includes(filter.value.toLowerCase());
        }  
            return String(content).toLowerCase().includes(filter.value.toLowerCase());
        
    }

    return true;
};

  return (
    <>
    {/* eslint-disable-next-line */}
      <ComponentCard title={t('LCFs')+' '+users?.length}>
        {user.role ==="admin" && <Button color="primary" onClick={toggleModal}>{t('Add New LCF')}</Button>}
        <Button color="secondary" onClick={() => exportPDF(columns, filteredData.length ? filteredData : users)}>{t('Print to PDF')}</Button>

        <TinkerTable
          columns={[
            {
              Header: t('Name'),
              id: 'fullName',
              accessor: row => `${row.name} ${row.lname}`,
            },
            {
              Header: t('Email'),
              accessor: 'email',
            },
            {
              Header: t('Country'),
              id: 'countryName',
              accessor: row => row.country ? row.country.name : t('N/A'),
            },
            {
              Header: t('Fee'),
              accessor: 'fee',
              Cell: props =>    props.value 
            },
            {
              Header: t('Status'),
              accessor: 'active',
              Cell: props => (
                <Badge color={props.value ? 'success' : 'danger'} pill>
                  {props.value ? t('Active') : t('Inactive')}
                </Badge>
              )
            },
            {
              Header: t('In business'),
              accessor: 'in_bussines',
              Cell: props => (
                <Badge color={props.value ? 'success' : 'danger'} pill>
                  {props.value ? t('Yes') : t('No')}
                </Badge>
              )
            },
            {
              Header: t('Actions'),
              id: 'actions',
              show: user.role === 'admin',
              minWidth: 150,
              Cell: ({ original }) => (
                <>
                  <Button color="info" size="sm" onClick={() => handleEdit(original)}>{t('Edit')}</Button>
                  {' '}
                  <Button color="danger" size="sm" onClick={() => handleDelete(original._id)}>{t('Delete')}</Button>
                  { user.role === 'admin' && <Button color="warning" size="sm" onClick={() => mascaradeAsUser(original)}>{t('Mascarede')}</Button>}

                </>
              )
            },
            {
              Header: t('Actions'),
              id: 'actions',
              show: user.role === 'lcf' || user.role === 'master',
              minWidth: 150,
              Cell: ({ original }) => (
                <>
                  <Button color="info" size="sm" onClick={() => handleEdit(original)}>{t('View')}</Button>
                </>
              )
            },
            
          ]}
          data={users}
          defaultFilterMethod={filterCaseInsensitive} 
        />
      </ComponentCard>

      <Modal isOpen={modal} toggle={toggleModal} size="lg">
      <Form onSubmit={handleSubmit}>
        <ModalHeader toggle={toggleModal}>{formData._id ? t('Edit LCF') : t('Add New LCF')}</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="country">{t('Country')}</Label>
            <Input 
              type="select" 
              name="country" 
              id="country" 
              value={formData.country} 
              onChange={handleInputChange} 
              required
              readOnly={isReadOnly}
            >
              <option value="">{t('Select a country')}</option>
              {countries.map(country => (
                <option key={country._id} value={country._id}>{country.name}</option>
              ))}
            </Input>
          </FormGroup>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="name">{t('First Name')}</Label>
                <Input 
                  type="text" 
                  name="name" 
                  id="name" 
                  value={formData.name} 
                  onChange={handleInputChange} 
                  required 
                  readOnly={isReadOnly}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="lname">{t('Last Name')}</Label>
                <Input 
                  type="text" 
                  name="lname" 
                  id="lname" 
                  value={formData.lname} 
                  onChange={handleInputChange} 
                  required 
                  readOnly={isReadOnly}
                />
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Label for="email">{t('Email')}</Label>
            <Input 
              type="email" 
              name="email" 
              id="email" 
              value={formData.email} 
              onChange={handleInputChange} 
              required 
              readOnly={isReadOnly}
            />
          </FormGroup>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="password">{t('Password')}</Label>
                <Input 
                  type="password" 
                  name="password" 
                  id="password" 
                  value={formData.password} 
                  onChange={handleInputChange} 
                  required 
                  readOnly={isReadOnly}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="repassword">{t('Re-enter Password')}</Label>
                <Input
                  type="password"
                  name="repassword"
                  id="repassword"
                  value={formData.repassword}
                  onChange={handleInputChange}
                  invalid={!!passwordError}
                  required
                  readOnly={isReadOnly}
                />
                <FormFeedback>{passwordError}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Label for="role">{t('Role')}</Label>
            <Input 
              type="select" 
              name="role" 
              id="role" 
              value={formData.role} 
              onChange={handleInputChange} 
              required
              readOnly={isReadOnly}
            >
              <option value="lcf">{t('LCF')}</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="fee">{t('Fee (%)')}</Label>
            <Input 
              type="number" 
              name="fee" 
              id="fee" 
              value={formData.fee} 
              onChange={handleInputChange} 
              required 
              min="0" 
              max="100" 
              step="0.01" 
              readOnly={isReadOnly}
            />
          </FormGroup>
          <FormGroup>
            <Label for="active">{t('Status')}</Label>
            <Input 
              type="select" 
              name="active" 
              id="active" 
              value={formData.active} 
              onChange={handleInputChange}
              readOnly={isReadOnly}
            >
              <option value="1">{t('Active')}</option>
              <option value="0">{t('Inactive')}</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="in_bussines">{t('In business')}</Label>
            <Input 
              type="select" 
              name="in_bussines" 
              id="in_bussines" 
              value={formData.in_bussines} 
              onChange={handleInputChange}
              readOnly={isReadOnly}
            >
              <option value="1">Yes</option>
              <option value="0">No</option>
            </Input>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit" disabled={isReadOnly}>{t('Save')}</Button>{' '}
          <Button color="secondary" onClick={toggleModal}>{t('Cancel')}</Button>
        </ModalFooter>
      </Form>
    </Modal>
    </>
  );
};

export default Lcfs;