import fetchRequest from '../auth/FetchInterceptorRequests'

const chatService = {
    getChatRooms: function () {
        return fetchRequest({
            url: '/digital/chat/rooms',
            method: 'get'
        })
    },

    getChatRoomMessages: function (roomId) {
        return fetchRequest({
            url: `/digital/chat/rooms/${roomId}`,
            method: 'get'
        })
    },

    sendMessage: function (roomId, content) {
        return fetchRequest({
            url: `/digital/chat/rooms/${roomId}/messages`,
            method: 'post',
            data: { content }
        })
    },

    createGroupChat: function (name, userIds) {
        return fetchRequest({
            url: '/digital/chat/rooms/group',
            method: 'post',
            data: { name, user_ids: userIds }
        })
    },

    markMessageAsRead: function (messageId) {
        return fetchRequest({
            url: `/digital/chat/messages/${messageId}/read`,
            method: 'post'
        })
    },

    startChat: function (userId) {
        return fetchRequest({
            url: '/digital/chat/rooms/start',
            method: 'post',
            data: { user_id: userId }
        })
    }
}

export default chatService;