import fetchRequest from '../auth/FetchInterceptorRequests'

const studentService = {}

studentService.getAll = function () {
	return fetchRequest({
		url: '/digital/students',
		method: 'get'
	})
}

studentService.getAllByMonth = function (month,year,uid=0) {
	return fetchRequest({
		url: `/digital/students/${month}/${year}/${uid}`,
		method: 'get'
	})
}


studentService.get = function (data) {
	return fetchRequest({
		url: `/digital/students/${data.id}`,
		method: 'get'
	})
}
studentService.createStudent = function (data) {
	return fetchRequest({
	  url: '/digital/students',
	  method: 'post',
	  data: data
	})
  }


studentService.statistic = function (data) {
	return fetchRequest({
		url: `/digital/students/statistic`,
		params: data,
		method: 'get'
	})
}

studentService.update = function (data) {
	return fetchRequest({
		url: `/digital/students/${data.id}`,
		method: 'put',
		data: data
	})
}
studentService.delete = function (id) {
	return fetchRequest({
		url: `/digital/students/${id}`,
		method: 'delete'
	})
}
export default studentService;