import fetchRequest from '../auth/FetchInterceptorRequests'

const calendarService = {}
calendarService.getByDateRange = function (start, end) {
    return fetchRequest({
        url: '/digital/calendar-entries-range',
        method: 'get',
        params: { start, end }
    });
}
calendarService.getCalendarEntriesForEvent = function (eventId) {
    return fetchRequest({
        url: `/digital/events/${eventId}/calendar-entries`,
        method: 'get'
    });
};

calendarService.getStudentsForCalendarEntry = function (calendarEntryId) {
    return fetchRequest({
        url: `/digital/calendar-entries/${calendarEntryId}/students`,
        method: 'get'
    });
};

calendarService.addStudentToCalendarEntry = function (calendarEntryId, studentId) {
    return fetchRequest({
        url: `/digital/calendar-entries/${calendarEntryId}/students`,
        method: 'post',
        data: { studentId }
    });
};

calendarService.removeStudentFromCalendarEntry = function (calendarEntryId, studentId) {
    return fetchRequest({
        url: `/digital/calendar-entries/${calendarEntryId}/students/${studentId}`,
        method: 'delete'
    });
};

calendarService.getCalendarEntry = function (calendarEntryId) {
    return fetchRequest({
        url: `/digital/calendar-entries/${calendarEntryId}`,
        method: 'get'
    });
};

calendarService.updateCalendarEntry = function (calendarEntryId, data) {
    return fetchRequest({
        url: `/digital/calendar-entries/${calendarEntryId}`,
        method: 'put',
        data
    });
};

calendarService.deleteCalendarEntry = function (calendarEntryId) {
    return fetchRequest({
        url: `/digital/calendar-entries/${calendarEntryId}`,
        method: 'delete'
    });
};
// New method to add a student to subsequent calendar entries
calendarService.addStudentToSubsequentEntries = function (calendarEntryId, studentId) {
    return fetchRequest({
        url: `/digital/calendar-entries/${calendarEntryId}/students/${studentId}/add-subsequent`,
        method: 'post'
    });
};

// New method to remove a student from subsequent calendar entries
calendarService.removeStudentFromSubsequentEntries = function (calendarEntryId, studentId) {
    return fetchRequest({
        url: `/digital/calendar-entries/${calendarEntryId}/students/${studentId}/remove-subsequent`,
        method: 'delete'
    });
};

calendarService.getSoftDeletedStudentsForEvent = function(eventId) {
    return fetchRequest({
      url: `/digital/events/${eventId}/soft-deleted-students`,
      method: 'get'
    });
  };

  // You might also want to add a method to restore a soft-deleted student
  calendarService.restoreStudent = function(eventId, studentId) {
    return fetchRequest({
      url: `/digital/events/${eventId}/restore-student/${studentId}`,
      method: 'post'
    });
  };

  calendarService.getAll = function () {
    return fetchRequest({
        url: '/digital/calendars',
        method: 'get'
    });
}

export default calendarService;