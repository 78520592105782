import fetchRequest from '../auth/FetchInterceptorRequests'

const userService = {
    getByRole: function (role) {
        return fetchRequest({
            url: `/digital/users/role/${role}`,
            method: 'get'
        })
    },
    notifyCenter: function(email, title, description){
        return fetchRequest({
            url: `/digital/users/notify`,
            method: 'post',
            data: {
                email: email,
                title: title,
                description: description
            }
        })
    },

    createUser: function (userData) {
        return fetchRequest({
            url: '/digital/users',
            method: 'post',
            data: userData
        })
    },

    updateUser: function (id, userData) {
        return fetchRequest({
            url: `/digital/users/${id}`,
            method: 'put',
            data: userData
        })
    },

    deleteUser: function (id) {
        return fetchRequest({
            url: `/digital/users/${id}`,
            method: 'delete'
        })
    },

    getUserById: function (id) {
        return fetchRequest({
            url: `/digital/users/${id}`,
            method: 'get'
        })
    }
}

export default userService;