import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import chatService from '../../../services/ChatService';

export const fetchChats = createAsyncThunk(
  'chat/fetchChats',
  async (_, { rejectWithValue }) => {
    try {
      const response = await chatService.getChatRooms();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const selectChat = createAsyncThunk(
  'chat/selectChat',
  async (chatId, { rejectWithValue }) => {
    try {
      const response = await chatService.getChatRoomMessages(chatId);
      return { chatId, messages: response.data };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    chats: [],
    chatContent: null,
    chatSearch: '',
    loading: false,
    error: null,
  },
  reducers: {
    setChatSearch: (state, action) => {
      state.chatSearch = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChats.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchChats.fulfilled, (state, action) => {
        state.loading = false;
        state.chats = action.payload;
      })
      .addCase(fetchChats.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(selectChat.pending, (state) => {
        state.loading = true;
      })
      .addCase(selectChat.fulfilled, (state, action) => {
        state.loading = false;
        state.chatContent = action.payload.chatId;
        const chatIndex = state.chats.findIndex(chat => chat.id === action.payload.chatId);
        if (chatIndex !== -1) {
          state.chats[chatIndex].messages = action.payload.messages;
        }
      })
      .addCase(selectChat.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setChatSearch } = chatSlice.actions;

export default chatSlice.reducer;