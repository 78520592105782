import axios from 'axios';
import { notification } from 'antd';

  import { AUTH_TOKEN, TEMP_TOKEN } from '../constants/AuthConstant';
import { API_BASE_URL } from '../configs/AppConfig';

import { useTranslation } from '../hooks/useTranslation';

const unauthorizedCode = [401, 403]

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 1200000
})
const translate=(s)=>{
 
	return useTranslation.t(s)
}
// Config
const TOKEN_PAYLOAD_KEY = 'authorization'

// API Request interceptor
service.interceptors.request.use(config => {
	const jwtToken = localStorage.getItem(AUTH_TOKEN) || null;
	
	if (jwtToken) {
		config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`
	}

  	return config
}, error => {
	// Do something with request error here
	notification.error({
		message: 'Error'
	})
	
	Promise.reject(error)
})

// API respone interceptor
service.interceptors.response?.use( (response) => {
	return response?.data
}, (error) => {

	const notificationParam = {
		message: error?.response?.data.message || 'Error',
	}
	if (unauthorizedCode.includes(error.response?.status)) {
		if(localStorage.getItem(TEMP_TOKEN)){
			localStorage.removeItem(AUTH_TOKEN)
			localStorage.setItem(AUTH_TOKEN,localStorage.getItem(TEMP_TOKEN))
			localStorage.removeItem(TEMP_TOKEN)
			localStorage.setItem('user',localStorage.getItem('tempUser'))	
			localStorage.removeItem('tempUser')
			localStorage.removeItem('isMasquerading')
			window.location.reload()
		}

		notificationParam.message = translate('Authentication Fail')
 		notificationParam.description = error.response?.data.message? translate(error.response?.data.message) :translate('Please login again')
 		  localStorage.removeItem(AUTH_TOKEN)
		//localStorage.removeItem('firstLogin')
         localStorage.removeItem('isAuthed')
        localStorage.removeItem('role')
		 
	 
	}
 	

	if (error.response?.status === 404) {
		notificationParam.message = translate('Not Found')
	}

	 

	if (error.response?.status === 500) {
		notificationParam.message = translate('Internal Server Error')
	}
	
	if (error.response?.status === 508) {
		notificationParam.message = translate('Time Out')
	}

	notification.error(notificationParam)

	return Promise.reject(error);
});

export default service