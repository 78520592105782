import fetchRequest from '../auth/FetchInterceptorRequests'

const centerService = {}

centerService.getAll = function () {
	return fetchRequest({
		url: '/digital/centers',
		method: 'get'
	})
}

centerService.create = function (data) {
	return fetchRequest({
		url: '/digital/centers',
		method: 'post',
		data: data
	})
}

centerService.update = function (data) {
	return fetchRequest({
		url: `/digital/centers/${data._id}`,
		method: 'put',
		data: data
	})
}

centerService.get = function (data) {
	return fetchRequest({
		url: `/digital/centers/${data.id}`,
		method: 'get'
	})
}

export default centerService;